export const AvailableForLicensingProductItems = [
    {
      srno: 1,
      productID: "1002",
      rLDProduct_Dev: "Gaster",
      productName: "Famotidine Tablets (10 mg, 20 mg)",
      productDevStatus: "Done",
      pilotBE: "Done",
      scaleUp: "Done",
      pivotalBE: "Done",
      technologyTransfer: "Done",
      remark: "Filled With NMPA",
      avaliableIn: [
        {
          key: "USA",
          value: true,
        },
        {
          key: "EU",
          value: true,
        },
        {
          key: "China",
          value: false,
        },
        {
          key: "Austrilia",
          value: true,
        },
        {
          key: "S.E. Asia",
          value: true,
        },
        {
          key: "Other",
          value: true,
        },
      ],
    },
    {
      srno: 2,
      productID: "3001",
      rLDProduct_Dev: "Bradion",
      productName: "Sugammadex Injection 100 mg / ml ( 2 ml and 5 ml)",
      productDevStatus: "Done",
      pilotBE: "Done",
      scaleUp: "Done",
      pivotalBE: "Done",
      technologyTransfer: "Done",
      remark: "Filed with FDA",
      avaliableIn: [
        {
          key: "USA",
          value: false,
        },
        {
          key: "EU",
          value: false,
        },
        {
          key: "China",
          value: false,
        },
        {
          key: "Australia",
          value: false,
        },
        {
          key: "S.E. Asia",
          value: false,
        },
        {
          key: "Other",
          value: false,
        },
      ],
    },
    {
      srno: 3,
      productID: "1004",
      rLDProduct_Dev: "Plaquenil",
      productName: "Hydroxychloroquine Sulphate Tablets (200 mg)",
      productDevStatus: "Done",
      pilotBE: "Done",
      scaleUp: "Done",
      pivotalBE: "Done",
      technologyTransfer: "Done",
      remark: "ANDA Approved",
      avaliableIn: [
        {
          key: "USA",
          value: false,
        },
        {
          key: "EU",
          value: true,
        },
        {
          key: "China",
          value: true,
        },
        {
          key: "Australia",
          value: true,
        },
        {
          key: "S.E. Asia",
          value: true,
        },
        {
          key: "Other",
          value: true,
        },
      ],
    },
    {
      srno: 4,
      productID: "1005",
      rLDProduct_Dev: "Lyrica",
      productName:
        "Pregabalin Capsules  (25, 50, 75, 100, 150, 200, 225, & 300 mg)",
      productDevStatus: "Done",
      pilotBE: "Done",
      scaleUp: "Done",
      pivotalBE: "Done",
      technologyTransfer: "Done",
      remark: "EB DONE, Under Filing",
      avaliableIn: [
        {
          key: "USA",
          value: true,
        },
        {
          key: "EU",
          value: true,
        },
        {
          key: "China",
          value: true,
        },
        {
          key: "Australia",
          value: true,
        },
        {
          key: "S.E. Asia",
          value: false,
        },
        {
          key: "Other",
          value: true,
        },
      ],
    },
    {
      srno: 5,
      productID: "1006",
      rLDProduct_Dev: "Zyvox",
      productName: "Linezolid Tablets (600 mg)",
      productDevStatus: "Done",
      pilotBE: "Done",
      scaleUp: "Done",
      pivotalBE: "Done",
      technologyTransfer: "Done",
      remark: "EB DONE, Under Filing",
      avaliableIn: [
        {
          key: "USA",
          value: true,
        },
        {
          key: "EU",
          value: true,
        },
        {
          key: "China",
          value: true,
        },
        {
          key: "Australia",
          value: true,
        },
        {
          key: "S.E. Asia",
          value: false,
        },
        {
          key: "Other",
          value: true,
        },
      ],
    },
    {
      srno: 6,
      productID: "2002",
      rLDProduct_Dev: "Nimotop",
      productName: "Nimodipine Tablets (30 mg)",
      productDevStatus: "Done",
      pilotBE: "Done",
      scaleUp: "Done",
      pivotalBE: "Done",
      technologyTransfer: "Done",
      remark: "Under Review at NMPA",
      avaliableIn: [
        {
          key: "USA",
          value: true,
        },
        {
          key: "EU",
          value: true,
        },
        {
          key: "China",
          value: false,
        },
        {
          key: "Austrilia",
          value: true,
        },
        {
          key: "S.E. Asia",
          value: true,
        },
        {
          key: "Other",
          value: true,
        },
      ],
    },
    {
      srno: 7,
      productID: "2002",
      rLDProduct_Dev: "Dectanyl",
      productName: "Dexamethasone Acetate Tablets (0.5 mg , 0.75 mg)",
      productDevStatus: "Done",
      pilotBE: "Done",
      scaleUp: "Done",
      pivotalBE: "Done",
      technologyTransfer: "Done",
      remark: "Under Review at NMPA",
      // avaliableIn: ["USA", "EU", "Australia", "S.E. Asia", "Other"],
      avaliableIn: [
        {
          key: "USA",
          value: false,
        },
        {
          key: "EU",
          value: true,
        },
        {
          key: "China",
          value: false,
        },
        {
          key: "Austrilia",
          value: true,
        },
        {
          key: "S.E. Asia",
          value: true,
        },
        {
          key: "Other",
          value: true,
        },
      ],
    },
    {
      srno: 8,
      productID: "2005",
      rLDProduct_Dev: "USL",
      productName:
        "Chlorpromazine Hydrochloride Tablets (10 mg, 25 mg, 50 mg, 100 mg, 200 mg )",
      productDevStatus: "Done",
      pilotBE: "Done",
      scaleUp: "Done",
      pivotalBE: "Done",
      technologyTransfer: "Done",
      remark: "Filed with FDA",
      avaliableIn: [
        {
          key: "USA",
          value: false,
        },
        {
          key: "EU",
          value: false,
        },
        {
          key: "China",
          value: true,
        },
        {
          key: "Australia",
          value: true,
        },
        {
          key: "S.E. Asia",
          value: true,
        },
        {
          key: "Other",
          value: true,
        },
      ],
    },
    {
      srno: 9,
      productID: "4002",
      rLDProduct_Dev: "Noxafil",
      productName: "Posaconazole Tablets 100 mg",
      productDevStatus: "Done",
      pilotBE: "Done",
      scaleUp: "Done",
      pivotalBE: "Done",
      technologyTransfer: "Done",
      remark: "Filed with FDA",
      avaliableIn: [
        {
          key: "USA",
          value: false,
        },
        {
          key: "EU",
          value: true,
        },
        {
          key: "China",
          value: true,
        },
        {
          key: "Australia",
          value: true,
        },
        {
          key: "S.E. Asia",
          value: true,
        },
        {
          key: "Other",
          value: true,
        },
      ],
    },

    // {
    //   srno: 3,
    //   productID: "1003",
    //   rLDProduct_Dev: "Lipitor",
    //   productName: "Atorvastatin Calcium Trihydarte Tablets (10, 20, 40 mg)",
    //   productDevStatus: "Done",
    //   pilotBE: "Done",
    //   scaleUp: "",
    //   pivotalBE: "Pending",
    //   technologyTransfer: "Pending",
    //   remark: "Ready for TT",
    //   avaliableIn: [
    //     {
    //       key: "USA",
    //       value: true,
    //     },
    //     {
    //       key: "EU",
    //       value: true,
    //     },
    //     {
    //       key: "China",
    //       value: true,
    //     },
    //     {
    //       key: "Australia",
    //       value: true,
    //     },
    //     {
    //       key: "S.E. Asia",
    //       value: true,
    //     },
    //     {
    //       key: "Other",
    //       value: true,
    //     },
    //   ],
    // },
    // {
    //   srno: 4,
    //   productID: "1004",
    //   rLDProduct_Dev: "Plaquenil",
    //   productName: "Hydroxychloroquine Sulphate Tablets (200 mg)",
    //   productDevStatus: "Done",
    //   pilotBE: "Done",
    //   scaleUp: "Done",
    //   pivotalBE: "Done",
    //   technologyTransfer: "Done",
    //   remark: "ANDA Approved",
    //   avaliableIn: [
    //     {
    //       key: "USA",
    //       value: false,
    //     },
    //     {
    //       key: "EU",
    //       value: true,
    //     },
    //     {
    //       key: "China",
    //       value: true,
    //     },
    //     {
    //       key: "Australia",
    //       value: true,
    //     },
    //     {
    //       key: "S.E. Asia",
    //       value: true,
    //     },
    //     {
    //       key: "Other",
    //       value: true,
    //     },
    //   ],
    // },
    // {
    //   srno: 5,
    //   productID: "1005",
    //   rLDProduct_Dev: "Lyrica",
    //   productName:
    //     "Pregabalin Capsules  (25, 50, 75, 100, 150, 200, 225, & 300 mg)",
    //   productDevStatus: "Done",
    //   pilotBE: "Done",
    //   scaleUp: "Done",
    //   pivotalBE: "Done",
    //   technologyTransfer: "Done",
    //   remark: "EB DONE, Under Filing",
    //   avaliableIn: [
    //     {
    //       key: "USA",
    //       value: true,
    //     },
    //     {
    //       key: "EU",
    //       value: true,
    //     },
    //     {
    //       key: "China",
    //       value: true,
    //     },
    //     {
    //       key: "Australia",
    //       value: true,
    //     },
    //     {
    //       key: "S.E. Asia",
    //       value: false,
    //     },
    //     {
    //       key: "Other",
    //       value: true,
    //     },
    //   ],
    // },
    // {
    //   srno: 6,
    //   productID: "1006",
    //   rLDProduct_Dev: "Zyvox",
    //   productName: "Linezolid Tablets (600 mg)",
    //   productDevStatus: "Done",
    //   pilotBE: "Done",
    //   scaleUp: "Done",
    //   pivotalBE: "Done",
    //   technologyTransfer: "Done",
    //   remark: "EB DONE, Under Filing",
    //   avaliableIn: [
    //     {
    //       key: "USA",
    //       value: true,
    //     },
    //     {
    //       key: "EU",
    //       value: true,
    //     },
    //     {
    //       key: "China",
    //       value: true,
    //     },
    //     {
    //       key: "Australia",
    //       value: true,
    //     },
    //     {
    //       key: "S.E. Asia",
    //       value: false,
    //     },
    //     {
    //       key: "Other",
    //       value: true,
    //     },
    //   ],
    // },
    // {
    //   srno: 7,
    //   productID: "1007",
    //   rLDProduct_Dev: "Benicar HCT",
    //   productName: "Olmesartan + HCTZ Tablets (20 + 12.5, 40 + 12.5, 40 + 25 mg)",
    //   productDevStatus: "Done",
    //   pilotBE: "Done",
    //   scaleUp: "",
    //   pivotalBE: "Pending",
    //   technologyTransfer: "Pending",
    //   remark: "Scale Up planned",
    //   avaliableIn: [
    //     {
    //       key: "USA",
    //       value: true,
    //     },
    //     {
    //       key: "EU",
    //       value: true,
    //     },
    //     {
    //       key: "China",
    //       value: true,
    //     },
    //     {
    //       key: "Australia",
    //       value: true,
    //     },
    //     {
    //       key: "S.E. Asia",
    //       value: false,
    //     },
    //     {
    //       key: "Other",
    //       value: true,
    //     },
    //   ],
    // },
    // {
    //   srno: 8,
    //   productID: "1008",
    //   rLDProduct_Dev: "Dulcolax DR Tablets",
    //   productName: "Bisacodyl Enteric Coated Tablets (5 mg)",
    //   productDevStatus: "Done",
    //   pilotBE: "Done",
    //   scaleUp: "Done",
    //   pivotalBE: "",
    //   technologyTransfer: "Pending",
    //   remark: "Development Done",
    //   avaliableIn: [
    //     {
    //       key: "USA",
    //       value: true,
    //     },
    //     {
    //       key: "EU",
    //       value: false,
    //     },
    //     {
    //       key: "China",
    //       value: true,
    //     },
    //     {
    //       key: "Australia",
    //       value: true,
    //     },
    //     {
    //       key: "S.E. Asia",
    //       value: true,
    //     },
    //     {
    //       key: "Other",
    //       value: true,
    //     },
    //   ],
    // },
    // {
    //   srno: 9,
    //   productID: "1009",
    //   rLDProduct_Dev: "Procomp",
    //   productName: "Prochlorprrazine Maleate Tablets (5 mg and 10 mg)",
    //   productDevStatus: "Done",
    //   pilotBE: "Done",
    //   scaleUp: "Done",
    //   pivotalBE: "",
    //   technologyTransfer: "TechnologyTransfer",
    //   remark: "EB Planned",
    //   avaliableIn: [
    //     {
    //       key: "USA",
    //       value: false,
    //     },
    //     {
    //       key: "EU",
    //       value: true,
    //     },
    //     {
    //       key: "China",
    //       value: true,
    //     },
    //     {
    //       key: "Australia",
    //       value: true,
    //     },
    //     {
    //       key: "S.E. Asia",
    //       value: true,
    //     },
    //     {
    //       key: "Other",
    //       value: true,
    //     },
    //   ],
    // },
  ];
  
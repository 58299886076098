import React from "react";
import { Link } from "react-router-dom";
import Research from "../../images/Research.png";
import PreFormulation from "../../images/preFormulation.png";
import Technicalsupport from "../../images/technical-support.png";
import Analytical from "../../images/analytics.png";
import "../../App.css";
// import ComplexGenerics from "./ComplexGenerics";
// import GenericsProduct from "./GenericsProduct";
// import DifferntiatedProdDev from "./DifferntiatedProdDev";
// import InnovativeProdDev from "./InnovativeProdDev";
// import RiBHUS from "../../images/RiBHUS12.png"
import "./Pipeline.css";
import CDMOIMG from "../../images/cdmo_EndToEnd.png";

const Pipeline = () => {
  return (
    <div className="pipeline">
      <div className="PipelineBG"></div>
      <div className="Discription">
        {/* <p>
          DelNova provides flexible services for contract development and
          manufacturing based on Quality by Design principle, including clinical
          trial materials, product development, commercial manufacturing and
          stabilization with Innovative packaging solutions.
        </p> */}
      </div>
      <div className="Border_Bottom"></div>
      <div className="CDMO">
        <h1 className="HHeading">
        We are - A Full Service CDMO with End-to-End Capabilities.
        </h1>
        <img src={CDMOIMG} alt="CDMO"></img>
        <div className="Discription">
        <p >
      DelNova provides services for contract development and manufacturing based on Quality in Design principle. We support Generic and Innovator companies to develop Stable and Bioequivalent formulation with robust process. 
      We support our partners with either Patent non-infringing formulations and Process thereof or innovative formulation approach. We support for Clinical trial materials (First In Human Dose) , Product development, Analytical Method Development and Validation, Commercial manufacturing and stabilization of product with Innovative packaging solutions.
      Our services are integrated with Business Automation Platform developed by Glogic Team where we can monitor the real time utilization of equipment’s and instruments. This help us to keep track of project cost and keep transparent sharing of efforts. 
      </p>
      </div>
      </div>

      <div className="Border_Bottom"></div>
      <div className="PagesCards">
        <h1 className="HHeading">
        Our Support to Partners with Various Services for Timely Product development.
        </h1>
        <div className="PageCard1">
          <h1 className="PageCardTitle">
            Pre-Formulation <br></br>Services
          </h1>
          <img
            className="PageCardTitleIcon"
            src={PreFormulation}
            alt="About"
          ></img>
          <p className="PageDiscriptionShort">
          DelNova is a fully integrated biopharmaceutical company with capabilities to develop and manufacture small and large molecules. 
          We support our partners with Preformulation services, as it is backbone of any robust formulation. 
          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/PreFormulServices">
              Learn more
            </Link>
          </p>
        </div>

        <div className="PageCard2">
          <h1 className="PageCardTitle">
            Formulation Development <br></br>Services
          </h1>
          <img className="PageCardTitleIcon" src={Research} alt="About"></img>
          <p className="PageDiscriptionShort">
          We are built on patient-focused innovation and development of drug delivery platforms. 
          Our Formulation expertise with blend of young and experience scientist will help to develop robust and scalable process. Our PKPD team will support to have “right at first time” formulation, while our IP team will help to get in for early and timely launch. 

          </p>
          <p className="PageLink">
            <Link
              className="hover-underline-animation"
              to="/FormulDevelopServices"
            >
              Learn more
            </Link>
          </p>
        </div>
        <div className="PageCard3">
          <h1 className="PageCardTitle">
            Technical <br></br>Services
          </h1>
          <img
            className="PageCardTitleIcon"
            src={Technicalsupport}
            alt="About"
          ></img>
          <p className="PageDiscriptionShort">
          We understand Quality aspect of Formulation and Analytical Development. We understand the requirement and cost of setting up of State of Art Facility. 
          Thus, we provide services to budding scientist and entrepreneurs to develop formulation at our <b>“ViRACS</b> Innovation Centre”. 
          It is dreamed to be centre of excellence for any kind of healthcare product development,  
          which are integrated with Business Automation Platform developed and monitored by <b>GLogic</b> Team 

          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/TechnicalServices">
              Learn more
            </Link>
          </p>
        </div>
        <div className="PageCard4">
          <h1 className="PageCardTitle">
            Analytical <br></br>Services
          </h1>
          <img className="PageCardTitleIcon" src={Analytical} alt="About"></img>
          <p className="PageDiscriptionShort">
          We understand importance of Analytical Development in success of any product throughout its Lifecycle. 
          Our team is well versed with ICH guidelines and requirements to have Accurate, 
          Precise, Robust and reproducible methods for analysis.  
          We equally provide services for Analytical Method Development and Analytical Method Validation to small and large companies including 
          drug development companies. 

          </p>
          <p className="PageLink">
            <Link
              className="hover-underline-animation"
              to="/AnalyticalServices"
            >
              Learn more
            </Link>
            
          </p>
        </div>
      </div>

      {/* <DifferntiatedProdDev />
      <InnovativeProdDev />
      <ComplexGenerics />
      <GenericsProduct /> */}
      {/* <div className="PipelinePage">
        <div className="Pipeline">
          <h1 className="HHeading">Pipeline</h1>
        </div>
        <div className="product_List">
          <div className="ProdDevProgressDetails">{renderBody()}</div>
        </div>
      </div> */}
    </div>
  );
};
export default Pipeline;

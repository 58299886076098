import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { DifferntiatedProdDevItems } from "./DifferntiatedProdDevItems";

import "../../App.css";
import ContactUsCard from "../ContactUsCard";

const DifferntiatedProdDev = () => {
  const [divName, setDivName] = useState("");
  const OpenLicensing = (tableElement) => {
    if (divName === tableElement) {
      setDivName("");
    } else {
      setDivName(tableElement);
    }
  };

  const renderBody = () => {
    // console.log("Rendered again");
    return DifferntiatedProdDevItems.map(
      (
        {
          srno,
          productID,
          rLDProduct_Dev,
          productName,
          productDevStatus,
          pilotBE,
          scaleUp,
          pivotalBE,
          technologyTransfer,
          remark,
          avaliableIn,
        },
        id
      ) => {
        return (
          <div className="ProductSection" key={id}>
            {/* <h1>{srno}</h1> */}
            <h2 className="ProductName">{productName}</h2>
            <h4 className="ProductRLDdev">{rLDProduct_Dev}</h4>
            <button
              className="onclickoutlic"
              onClick={() => {
                OpenLicensing(productName);
              }}
            >
              Reach For Licensing
            </button>
            <section className="Tbody">
              <div className="table-row">
                {/* <td>{productID}</td> */}
                {/* // */}
                {productDevStatus === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>Development Status</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress BlueProLine">
                    <i className="fas fa-sync"></i>
                    <p>Development Status</p>
                  </div>
                )}
                {pilotBE === "" ? (
                  <div className="ProdDevProgress OrangeProLine">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>PilotBE</p>
                  </div>
                ) : pilotBE === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>PilotBE</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress GrayProLine">
                    <i className="fas fa-times-circle"></i>
                    <p>PilotBE</p>
                  </div>
                )}
                {scaleUp === "" ? (
                  <div className="ProdDevProgress OrangeProLine">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>Scale Up</p>
                  </div>
                ) : scaleUp === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>Scale Up</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress GrayProLine">
                    <i className="fas fa-times-circle"></i>
                    <p>Scale Up</p>
                  </div>
                )}
                {pivotalBE === "" ? (
                  <div className="ProdDevProgress OrangeProLine">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>PivotalBE</p>
                  </div>
                ) : pivotalBE === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>PivotalBE</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress GrayProLine">
                    <i className="fas fa-times-circle"></i>
                    <p>PivotalBE</p>
                  </div>
                )}

                {technologyTransfer === "" ? (
                  <div className="ProdDevProgress OrangeProLine">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>Dossier</p>
                  </div>
                ) : technologyTransfer === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>Dossier</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress GrayProLine">
                    <i className="fas fa-times-circle"></i>
                    <p>Dossier</p>
                  </div>
                )}
              </div>
            </section>
            <div
              className={
                productName === divName
                  ? "OutLicensing_List viewMore"
                  : "OutLicensing_List"
              }
            >
              <table className="OutLicTable">
                <thead className="OutLicTableHead">
                  <tr>
                    <th className="OutLicTableHeadValue">Region</th>
                    <th className="OutLicTableHeadValue">USA</th>
                    <th className="OutLicTableHeadValue">EU</th>
                    <th className="OutLicTableHeadValue">China</th>
                    <th className="OutLicTableHeadValue">Australia</th>
                    <th className="OutLicTableHeadValue">S.E. Asia</th>
                    <th className="OutLicTableHeadValue">Other</th>
                  </tr>
                </thead>
                <tbody className="OutLicTableBody">
                  <tr className="OutLicTableBodyRow">
                    <td className="OutLicTableBodyValue">Status</td>
                    {avaliableIn.map(function (item, id) {
                      return (
                        <td
                          key={id}
                          className={
                            item.value
                              ? "outLicTableValue Avaliable"
                              : "outLicTableValue notAvaliable"
                          }
                        >
                          {item.value ? (
                            <i className="fas fa-check tooltip">
                              <span className="tooltiptext">Avaliable</span>
                            </i>
                          ) : (
                            <i className="fas fa-times tooltip">
                              <span className="tooltiptext">Not Avaliable</span>
                            </i>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="outLicRemarkRow">
                    <td className="outLicRemarValue"> Remarks</td>
                    <td className="outLicRemarValue" colSpan={6}>
                      {remark}
                    </td>
                  </tr>
                  <tr className="outLicContactUsBTNRow">
                    <td className="outLicContactUsBTN" colSpan={7}>
                      <Link className="ProductTableBTN" to="/ContactUs">
                        Contact Us
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    );
  };
  return (
    <div className="FullSize DifferentiatedProduct">
      <div className="PipelinePage">
        <div className="DifferentiatedProductTopBG"></div>
        <div className="Pipeline">
          <h1 className="HHeading">Differentiated Product</h1>
        </div>
        <div className="product_List">
          <p className="ShortDiscription">
          DelNova is a fully integrated biopharmaceutical company with capabilities to develop and manufacture small and large molecules, offering next-generation drug delivery technologies, affordable and clinically proven products.
          </p>
          <h1 className="HHeading">Differentiated Products: </h1>
          <div className="ProdDevProgressDetails">{renderBody()}</div>
        </div>
      </div>
      <ContactUsCard sender={"Diff"} />
    </div>
  );
};

export default DifferntiatedProdDev;

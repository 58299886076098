import React from "react";
import RoomIcon from "@mui/icons-material/Room";

import "./Careers.css";
import "../../App.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { OpenPositions } from "./CareersOpenPositions";

// import ApplyHere from "./ApplyHere";

export default function Careers() {
  const [viewMore, setViewMore] = useState("");
  const handleViewMore = (cardvalue) => {
    if (viewMore === cardvalue) {
      setViewMore("");
    } else {
      setViewMore(cardvalue);
    }
  };
  const renderJobsBody = () => {
    return OpenPositions.map(
      (
        {
          srno,
          positionID,
          department,
          position,
          technicalRequirements,
          job_Requirements,
          jobLocation,
          jobDiscription,
        },
        id
      ) => {
        return (
          <div
            className={
              viewMore === `card${srno}`
                ? "JobOffer_Details viewMore"
                : "JobOffer_Details"
            }
            onClick={() => handleViewMore(`card${srno}`)}
            key={id}
          >
            <h2 className="Job_Title">{position}</h2>
            <ul className="Job_Requirements">
              {job_Requirements.map(function (item, id) {
                return <li key={id}>{item}</li>;
              })}
            </ul>
            <h4 className="Job_Location">
              <i>
                <RoomIcon fontSize="small" />
              </i>
              {jobLocation}
            </h4>
            <button
              className="JDApplyHereForm"
              onClick={() => handleViewMore(`card${srno}`)}
            >
              {viewMore === `card${srno}` ? "See Less" : "See More"}
            </button>
            <h6 className="sec__heading">Technical Requirements</h6>
            <ol className="JobDiscription">
              {technicalRequirements.map(function (item, id) {
                return <li key={id}>{item}</li>;
              })}
            </ol>
            <h6 className="sec__heading">Job Description</h6>
            <ol className="JobDiscription">
              {jobDiscription.map(function (item, id) {
                return <li key={id}>{item}</li>;
              })}

              <p className="ViewMoreApplyHereBTN">
                <Link
                  to="/ApplyHere"
                  state={{
                    from: position,
                  }}
                >
                  Apply Here
                </Link>
              </p>
            </ol>
          </div>
        );
      }
    );
  };

  return (
    <div className="FullSize careers">
      <div className="CareersPage">
        <div className="CareersBG"></div>
        <div className="Discription">
          <p>
            <span>We are a People-Oriented​ Organization </span>
            <br></br>
            We empower our employees to continuously innovate by providing them
            with state-of-the-art facilities and tools needed to meet the
            demands and regulations of today’s rapidly changing
            biopharmaceutical market.
          </p>
        </div>
        <div className="Border_Bottom"></div>

        <div className="JobOffers">{renderJobsBody()}</div>
      </div>
    </div>
  );
}

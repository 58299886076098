import React from "react";
import "../../App.css";

const InLicensing = () => {
  return (
    <div className="FullSize">
      <h1 className="inLicensing">In Licensing</h1>
    </div>
  );
};

export default InLicensing;

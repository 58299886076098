// import React from "react";
import React, { useRef } from "react";
import emailjs from "emailjs-com";
import "./ContactUsForm.css";

const ContactUsForm = (props) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8400cmc",
        "template_ohp6ofd",
        form.current,
        "user_97BArShYWL8HlU7aUDVzW"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="Contact_Us_Form">
      <h2 className="Primery HHeading">
        {props.HeadLine ? props.HeadLine : "share Your Idea"}
      </h2>
      <form className="Form" autoComplete="on" ref={form} onSubmit={sendEmail}>
        <div className="Contact_Us_Form_Inputs">
          <label>Name :</label>
          <input type="text" name="from_name"></input>
        </div>
        <div className="Contact_Us_Form_Inputs">
          <label>Organization :</label>
          <input type="text" name="user_organization"></input>
        </div>
        <div className="Contact_Us_Form_Inputs">
          <label>Email :</label>
          <input
            type="email"
            formNoValidate={true}
            name="user_email"
            required
          ></input>
        </div>
        <div className="Contact_Us_Form_Inputs">
          <label>Contact Number:</label>
          <input
            type="tel"
            pattern="[1-9]{1}[0-9]{9}"
            maxLength="10"
            name="user_Mo_Number"
          ></input>
        </div>
        <div className="Contact_Us_Form_Inputs">
          <label>Message :</label>
          <textarea
            type="text"
            className="Message_contactForm"
            name="message"
          ></textarea>
        </div>
        <input
          type="submit"
          value="Submit"
          // onClick={() => alert("Website is in Development")}
        ></input>
      </form>
    </div>
  );
};

export default ContactUsForm;

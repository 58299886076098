import React from "react";
import "../../App.css";
import preFormulation from "../../images/preFormulation.jpg";

const PreFormulServices = () => {
  return (
    <div className="FullSize PreFormulServices">
      <div className="PipelinePage">
        <div className="PreFormulServicesTopBG"></div>
        <div className="Pipeline">
          <br></br>
          <h1 className="HHeading">Our Preformulation Services</h1>
        </div>
        <div className="Services_List">
          <div style={{marginTop:"0.5rem"}}>
            <img src={preFormulation} alt="preFormulation"></img>
          </div>
          <ul className="ShortDiscription">
            <li>Physical properties and product characterization.</li>
            <li>Drug Substance - excipient interaction studies.</li>
            <li>API Characterization</li>
            <li>
              API Accelerated degradation (including an evaluation of the
              effects of pH, Heat, light, and atmospher).
            </li>
            <li>Solubility profiling in buffers and non-aqueous solvents</li>
            <li>Compatibility testing of APIs and excipients.</li>
            <li>Formulation and packaging compatibility studies</li>
            <li>
              Solid-state and solution-state stability and photo stability
            </li>
            <li>Hygroscopicity</li>
            <li>Morphology assessment</li>
            <li>Intrinsic dissolution</li>
            <li>Micromeritic studies</li>
            <li>Prototype evaluations.</li>
            <li>Reverse engineering.</li>
            <li>Other compatibility studies</li>
            <li>NCE screening for Product development</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PreFormulServices;

import React from "react";
import Formulation1 from "../../images/Formulation1.png";
import Formulation2 from "../../images/Formulation2.png";


const FormulDevelopServices = () => {
  return (
    <div className="FullSize FormulDevelopServices">
      <div className="PipelinePage">
        <div className="FormulDevelopServicesTopBG"></div>
        <div className="Pipeline">
          <br></br>
          <h1 className="HHeading">Our Formulation Development Services Includes</h1>
        </div>
        <div className="Services_List">
        <div style={{width:"50%", display:"flex", flexDirection:"column", alignItems:"center",marginTop:"0.5rem"}}>
        <img src={Formulation1} alt="Formulation1"  style={{width :"42%", height:"40%"}}></img>
        <br></br>
        <img src={Formulation2} alt="Formulation2" style={{width :"95%", height:"40%"}}></img>

        </div>
          <ul className="ShortDiscription">
          <li>Phase I-IV formulation development.</li>
          <li>Development of First In Human Dose Formulation.</li>
          <li>Developing Generics and Complex generics to Over-the-counter products.</li>
          <li>Developing line extensions to existing formulations for Life Cycle Management.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FormulDevelopServices;

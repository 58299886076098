import React from "react";
import "../../App.css";
import TechnicalServImage1 from "../../images/TechnicalServImage1.png";
import TechnicalServImage2 from "../../images/TechnicalServImage2.png";
import TechnicalServImage3 from "../../images/TechnicalServImage3.png";

const TechnicalServices = () => {
  return (
    <div className="FullSize TechnicalServices">
      <div className="PipelinePage">
        <div className="TechnicalServicesTopBG"></div>
        <div className="Pipeline">
          <br></br>
          <h1 className="HHeading">Technical Services</h1>
        </div>
      </div>
      <div className="Services_List">
      <div>
        <img src={TechnicalServImage1} alt="TechnicalServImage1"></img>
        {/* <br></br> */}
        <img src={TechnicalServImage2} alt="TechnicalServImage2"></img>
        <img src={TechnicalServImage3} alt="TechnicalServImage3"></img>
      </div>
        <div>
          <p className="ShortDiscription">
            <b>We support our Partners with two ways: </b>
          </p>
          <ul className="ShortDiscription">
            <li>Complete Product and Dossier Development </li>
            <li>Services based on facility usage.</li>
          </ul>
          <p className="ShortDiscription">
            <b>Additionally, we support our partners with: </b>
          </p>
          <ul className="ShortDiscription">
            <li>Troubleshooting of commercial products.</li>
            <li>
              Formulation optimization and evaluation of new raw materials in
              existing products.
            </li>
            <li>
              Technical transfer of products from R&D to production facilities.
            </li>
            <li>
              Process optimization and scale-up and assessment of current
              products.
            </li>
            <li>
              Modernizing existing manufacturing and clinical supplies processes
              using time and cost saving approaches like Direct Compression,
              Twin screw granulations and many such process with continuous
              manufacturing approach.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TechnicalServices;

import React from "react";
import { Link } from "react-router-dom";
import "./ContactUsCard.css";
// import ContactUsForm from "./pages/ContactUsForm";
// import ShareYourIdea from "./pages/ShareYourIdea";
import { useState } from "react";
import DelNovaProductList from "../images/DelNova_ProductList.xlsx";


const ContactUsCard = (props) => {
  const [headLine, setheadLine] = useState(null);
  const HeadLineSelector = () => {
    if (props.sender === "Diff" || props.sender === "Inno") {
      setheadLine("Share Your Idea Here");
    } else {
      setheadLine("Share Your Generic Product Idea ");
    }
  };

  return (
    <div className="ContactUs_Card">
      <div className="Card_Question">
        <h1 className="Ques">
          {props.sender === "Diff" || props.sender === "Inno"
            ? "Do you have any idea ?"
            : "Do you want to develop any new generic product?"}
        </h1>
        <button className="ContactBTN">
          <Link
            to="/ShareYourIdea"
            state={{ from: headLine }}
            className="OtherProductsLinkBTN"
            onMouseDown={() => HeadLineSelector()}
          >
            Contact Us
          </Link>
        </button>
      </div>
      <div className="OtherProducts">
        {props.sender === "Diff" ? (
          <div>
            <Link to="/InnovativeProdDev" className="OtherProductsLinkBTN">
              Innovative
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link to="/ComplexGenerics" className="OtherProductsLinkBTN">
              Complex
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link to="/GenericsProduct" className="OtherProductsLinkBTN">
              Generic
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        ) : (
          " "
        )}
        {props.sender === "Inno" ? (
          <div>
            <Link to="/DifferntiatedProdDev" className="OtherProductsLinkBTN">
              Differentiated
              <i className="fas fa-chevron-right"></i>
            </Link>

            <Link to="/ComplexGenerics" className="OtherProductsLinkBTN">
              Complex
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link to="/GenericsProduct" className="OtherProductsLinkBTN">
              Generic
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        ) : (
          " "
        )}
        {props.sender === "Comp" ? (
          <div>
            <Link to="/DifferntiatedProdDev" className="OtherProductsLinkBTN">
              Differentiated
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link to="/InnovativeProdDev" className="OtherProductsLinkBTN">
              Innovative
              <i className="fas fa-chevron-right"></i>
            </Link>

            <Link to="/GenericsProduct" className="OtherProductsLinkBTN">
              Generic
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        ) : (
          " "
        )}
        {props.sender === "Genr" ? (
          <div>
            <Link to="/DifferntiatedProdDev" className="OtherProductsLinkBTN">
              Differentiated
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link to="/InnovativeProdDev" className="OtherProductsLinkBTN">
              Innovative
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link to="/ComplexGenerics" className="OtherProductsLinkBTN">
              Complex
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        ) : (
          " "
        )}
      </div>
      <div style={{ padding: '20px', color: 'rgb(7, 99, 122)', fontFamily: 'Montserrat', fontSize: '0.8rem' }}>
      {/* <h2>Download Our PDF</h2> */}
      <h4 className="Ques">
         Download complete products list:
      </h4>
      <br></br>
      <a 
        // href="Delnova Product List _March 2022.xlsx" 
        //enable this after getting correct file
        href={DelNovaProductList} 
        download="DelNova_Product_List.xlsx" // Custom name for the downloaded file
        style={{ 
          padding: '10px 20px', 
          backgroundColor: '#007BFF', 
          color: '#fff', 
          textDecoration: 'none', 
          borderRadius: '20px' 
        }}
      >
        Download
      </a>
    </div>
    </div>
  );
};

export default ContactUsCard;

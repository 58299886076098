import React from "react";
import { useForm } from "react-hook-form";
import "./ApplyHere.css";
import { useLocation } from "react-router-dom";
import "./ShareYourIdea.css";

const ApplyHere = () => {
  const location = useLocation();
  const { from } = location.state;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  console.log(errors);
  return (
    <div className="ApplyHere">
      <h1 className="HHeading">Apply Here</h1>
      <h1 className="heading">Position : {from}</h1>
      <form className="ApplyHereForm" onSubmit={handleSubmit(onSubmit)}>
        <label> Name:</label>
        <input
          type="text"
          placeholder="Name"
          {...register("Name", { required: true, maxLength: 80 })}
        />
        <label> Email:</label>
        <input
          type="text"
          placeholder="Email"
          {...register("Email", { required: true, pattern: /^\S+@\S+$/i })}
        />
        <label>Number:</label>
        <input
          type="tel"
          placeholder="Mobile number"
          {...register("Mobile number", {
            required: true,
            minLength: 6,
            maxLength: 12,
          })}
        />
        <label>Job Title:</label>
        <input
          type="text"
          placeholder="Job Title"
          value={from}
          {...register("Job Title", { required: true })}
        />
        <label>Send Your Resume on: </label>
        {/* <input
          type="file"
          placeholder="Your Resume"
          {...register("Resume", { required: true })}
        /> */}
        <a href="mailto:hr@delnovahealth.com" target="_blank" rel="noreferrer">
          hr@delnovahealth.com
        </a>

        <input type="submit" />
      </form>
    </div>
  );
};

export default ApplyHere;

import React from "react";
import Missionvision from "../../images/mission_vision_final (3).jpg";
import Trustworthy from "../../images/medal.png";
import Commitment from "../../images/handshake.png";
import QualityInDesign from "../../images/light-bulb.png";
import Differentiation from "../../images/artificial-intelligence.png";
// import Innovation from "../../images/circuit.png";
import Innovation from "../../images/sun.png";

import "../../App.css";
import "./Mission.css";

const Mission = () => {
  return (
    <div className=" mission">
      <div className="MissionPage">
        <div className="MissionVision">
          <div className="MissionSec">
            <h2 className="HHeading ">MISSION</h2>
            <h3 className="heading">
              <span className="newFontTagline">
                “Reducing the burden of Healthcare”.
              </span>
              <br></br> Around the world through exceptional partnership.
            </h3>
          </div>
          <div className="MissionVisionImg">
            <img src={Missionvision} alt="Mission vision"></img>
          </div>
          <div className="VisionSec">
            <h2 className="HHeading ">VISION</h2>
            <h3 className="heading">
              <span className="VisionFirstLine">
                To become the most valued healthcare partners
              </span>{" "}
              <br></br>Through Innovation guided by a firm commitment.
            </h3>
          </div>
        </div>
      </div>
      <div className="Border_Bottom"></div>
      <div>
        <div className="CoreValuesSec">
          <h2 className="HHeading ">OUR VALUES</h2>
          <h3 className="CoreValuesTagLine">
            We are committed to adhere to our values, which gives our partners a
            sustainable growth.
          </h3>
          <div className="CoreValuesImg">
            <div className="Ourvalue">
              <img src={Trustworthy} alt="Trustworthy icon"></img>
              <p className="ourValueName">Trustworthy</p>
            </div>
            <div className="Ourvalue">
              <img src={Commitment} alt="Commitment icon"></img>
              <p className="ourValueName">Commitment</p>
            </div>
            <div className="Ourvalue">
              <img src={QualityInDesign} alt="Quality In Design icon"></img>
              <p className="ourValueName">
                Quality <span className="Special_wordIn">iN</span> Design
              </p>
            </div>
            <div className="Ourvalue">
              <img src={Differentiation} alt="Differentiation icon"></img>
              <p className="ourValueName">Differentiation</p>
            </div>
            <div className="Ourvalue">
              <img src={Innovation} alt="Innovation icon"></img>
              <p className="ourValueName">Innovation</p>
            </div>
          </div>
          <h2 className="CoreValuesBottomTagLine">
            Its not Just what we do, it’s the way that we do it.
          </h2>
        </div>
      </div>
      <div className="Border_Bottom"></div>
    </div>
  );
};

export default Mission;

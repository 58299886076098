import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { createBrowserHistory } from "history";
// import { browserHistory, IndexRoute } from "react-router";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop.js";
import Home from "./components/pages/Home.js";
import FooterDelNova from "./components/FooterDelNova";
import "./App.css";
import AboutUs from "./components/pages/AboutUs.js";
import ApplyHere from "./components/pages/ApplyHere.js";
// import CompanyOverview from "./components/pages/CompanyOverview.js";
// import LeadershipTeam from "./components/pages/CoreTeam.js";
// import Mission from "./components/pages/Mission.js";
import ResearchAndDevlop from "./components/pages/ResearchAndDevlop.js";
import ShareYourIdea from "./components/pages/ShareYourIdea.js";
import DifferntiatedProdDev from "./components/pages/DifferntiatedProdDev.js";
import InnovativeProdDev from "./components/pages/InnovativeProdDev.js";
import ComplexGenerics from "./components/pages/ComplexGenerics.js";
import GenericsProduct from "./components/pages/GenericsProduct.js";
import OurPresence from "./components/pages/OurPresence.js";
import Pipeline from "./components/pages/Pipeline.js";
import PreFormulServices from "./components/pages/PreFormulServices.js";
import FormulDevelopServices from "./components/pages/FormulDevelopServices.js";
import TechnicalServices from "./components/pages/TechnicalServices.js";
import AnalyticalServices from "./components/pages/AnalyticalServices.js";
import InLicensing from "./components/pages/InLicensing.js";
import OutLicensing from "./components/pages/OutLicensing.js";
import Careers from "./components/pages/Careers.js";
import ContactUs from "./components/pages/ContactUs.js";
import ContactUsForm from "./components/pages/ContactUsForm.js";
import ContactUsCard from "./components/ContactUsCard.js";
import DunsTradeMark from "./components/DunsTradeMark.js";
import AvailableForLicensing from "./components/pages/AvailableForLicensing";
// import SignUp from "./components/pages/SignUp";
// import { useEffect } from "react";
const DropdownNav = () => {
  const routes = useRoutes([
    // { IndexRoute component={Home}},
    { path: "/", element: <Home /> },
    { path: "AboutUs", element: <AboutUs /> },
    // {
    //   path: "CompanyOverview",
    //   element: (
    //     <AboutUs>
    //       <CompanyOverview />
    //     </AboutUs>
    //   ),
    // },
    // {
    //   path: "Mission",
    //   element: (
    //     <AboutUs>
    //       <Mission />
    //     </AboutUs>
    //   ),
    // },
    // {
    //   path: "CoreTeam",
    //   element: (
    //     <AboutUs>
    //       <LeadershipTeam />
    //     </AboutUs>
    //   ),
    // },
    { path: "ApplyHere", element: <ApplyHere /> },
    { path: "ResearchAndDevlop", element: <ResearchAndDevlop /> },
    { path: "DifferntiatedProdDev", element: <DifferntiatedProdDev /> },
    { path: "InnovativeProdDev", element: <InnovativeProdDev /> },
    { path: "ComplexGenerics", element: <ComplexGenerics /> },
    { path: "GenericsProduct", element: <GenericsProduct /> },
    { path: "AvailableForLicensing", element: <AvailableForLicensing /> },
    { path: "OurPresence", element: <OurPresence /> },
    { path: "Pipeline", element: <Pipeline /> },
    { path: "InLicensing", element: <InLicensing /> },
    { path: "OutLicensing", element: <OutLicensing /> },
    { path: "Careers", element: <Careers /> },
    { path: "ContactUs", element: <ContactUs /> },
    { path: "ContactUsForm", element: <ContactUsForm /> },
    { path: "ContactUsCard", element: <ContactUsCard /> },
    { path: "ShareYourIdea", element: <ShareYourIdea /> },
    { path: "PreFormulServices", element: <PreFormulServices /> },
    { path: "FormulDevelopServices", element: <FormulDevelopServices /> },
    { path: "TechnicalServices", element: <TechnicalServices /> },
    { path: "AnalyticalServices", element: <AnalyticalServices /> },
    { path: "DunsTradeMark", element: <DunsTradeMark /> },
    // { path: "signUp", element: <SignUp /> },
  ]);

  return routes;
};

function App() {
  return (
    <Router history={createBrowserHistory}>
      <ScrollToTop />
      <Navbar />
     
      <DropdownNav />
      <FooterDelNova />
    </Router>
  );
}

export default App;

// import React, { Component } from "react";
// import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import React from "react";
import "../../App.css";
import "./OurPresence.css";
// import img1 from "../../images/img-1.jpg";
// import img2 from "../../images/img-2.jpg";
// import img3 from "../../images/img-3.jpg";
// import img4 from "../../images/img-4.jpg";
// import img5 from "../../images/img-5.jpg";
// import img6 from "../../images/img-6.jpg";
// import img7 from "../../images/img-7.jpg";
// import OurPresenceMap from "../../images/Our Presence Map.png";

const OurPresence = () => {
  // const createCarouselItemImage = (index, options = {}) => (
  //   <div key={index}>
  //     <img src={`/assets/${index}.jpeg`} />
  //     <p className="legend">Legend {index}</p>
  //   </div>
  // );

  return (
    <div className="FullSize ourPresence">
      <div className="Our_Presence_Bg">
        <h2 className="HHeading">Global Presence</h2>
      </div>
      <div className="Discription">
        <p>
          ‘Product Development Partner’ for the ‘Generic and Complex Generic
          Drug Delivery Systems’<br></br> With Differentiated & Innovative
          Technology and Processes,<br></br>
          Will Help to Meet the Time Lines, Cost, thereby Achieving the Mission
          <span className="newFontTagline">
            “Reducing the burden of Healthcare”.
          </span>
        </p>
      </div>
      <div className="Border_Bottom"></div>
      <h1> WE ARE GROWING</h1>
      <div className="OurPresenceMap">
        {/* <img src={OurPresenceMap} alt="OurPresenceMap"></img> */}
      </div>
      {/* <h1> OUR GALLERY</h1> */}
      {/* <div className="OurPresenceCarousel"> */}
    </div>

    //Carousel
    // showThumbs="false"
    // autoPlay="true"
    // interval="3000"
    // infiniteLoop="true"
    // useKeyboardArrows="true"
    // stopOnHover="true"
    // centerMode="true"
    // centerSlidePercentage="95"
    //unused attributes below
    // thumbWidth="100"
    // showStatus="false"
    // onClickItem="true"
    // swipeable="true"
    // showArrows={true}
    // onChange
    // onClickItem="true"
    // onClickThumb

    /* <div>
            <img src={img1} alt="1" />
            <p className="legend">Legend 1</p>
          </div>
          <div>
            <img src={img2} alt="2" />
            <p className="legend">Legend 2</p>
          </div>
          <div>
            <img src={img3} alt="3" />
            <p className="legend">Legend 3</p>
          </div>
          <div>
            <img src={img4} alt="4" />
            <p className="legend">Legend 4</p>
          </div>
          <div>
            <img src={img5} alt="5" />
            <p className="legend">Legend 5</p>
          </div>
          <div>
            <img src={img6} alt="6" />
            <p className="legend">Legend 6</p>
          </div>
          <div>
            <img src={img7} alt="7" />
            <p className="legend">Legend 7</p>
          </div>
        </Carousel> */

    //</div>
  );
};
export default OurPresence;

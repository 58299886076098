import React from "react";
import "../../App.css";
import AnalyticalServImage1 from "../../images/AnalyticalServImage1.png";
import AnalyticalServImage2 from "../../images/AnalyticalServImage2.png";

const AnalyticalServices = () => {
  return (
    <div className="FullSize AnalyticalServices">
      <div className="PipelinePage">
        <div className="AnalyticalServicesTopBG"></div>
        <div className="Pipeline">
          <h1 className="HHeading">Analytical Services</h1>
        </div>

        <div className="Services_List">
          <div>
            <img src={AnalyticalServImage1} alt="AnalyticalServImage1"  style={{ height:"45%"}}></img>
            <img src={AnalyticalServImage2} alt="AnalyticalServImage2" style={{ height:"45%"}}></img>
          </div>
          <div>
            <p className="ShortDiscription">
              The Analytical team at DelNova are completely integrated, with
              formulation, and Quality Assurance scientist. They work in team
              with each other, to ensure effective and accelerated product
              development. Our expertise in developing methods for highly
              complex and unstable drug products compliments our formulation
              capabilities and help us to meet timelines.
            </p>
            <p className="ShortDiscription">
              <b>
                Our strong understanding of Chemistry help us to support our
                Partners with :{" "}
              </b>
            </p>
            <ul className="ShortDiscription">
              <li>Analytical Method Development </li>
              <li>Analytical Method Validation.</li>
            </ul>

            <p className="ShortDiscription">
              <b>Additionally, we support our partners with: </b>
            </p>
            <ul className="ShortDiscription">
              <li>
                Development and fine tuning of Analytical methods with QbD
                Approach,
              </li>
              <li>Reverse Engineering of Reference product</li>
              <li>Analytical Method Transfer </li>
              <li>Cleaning Method Validation</li>
              <li>Cost effective and shortest run time methods.</li>
              <li>
                Stability indicating analytical methods that are reliable and
                consistent in nature for the evaluation of drug products to meet
                the standard of potency, purity and stability.{" "}
              </li>
              <li>
                Forced degradation studies - to understand the degradation
                pattern and suitable stability indicating method{" "}
              </li>
              <li>
                API Characterization – Accelerate degradation, Solubility
                profiling{" "}
              </li>
              <li>Reverse engineering studies of the innovator product</li>
              <li>Stability studies as per ICH guidelines</li>
              <li>Pharmaceutical equivalence studies/multimedia dissolution</li>
              <li>Development of stability indication analytical methods</li>
              <li>Innovator Product Characterization</li>
              <li>Photo stability studies as per ICH</li>
              <li>Specifications development</li>
              <li>
                Dissolution Profile Mapping for Pharmaceutical Equivalence
              </li>
              <li>Regulatory support</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticalServices;

import React, { useState } from "react";
import ResAndDevDropdown from "./ResAndDevDropdown";
import "./Navbar.css";
import { Link } from "react-router-dom";
import DelNovaLOGO from "../images/DelnovaLogoF2.png";

function Navbar() {
  const [click, setClick] = useState(false);
  const [menuSelected, setMenuSelected] = useState();
  const [ResearchAndDevlopDropdown, setResearchAndDevlopDropdown] =
    useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const SetMenuItem = (MenuItem) => {
    setMenuSelected(MenuItem);
  };
  const onResAndDevMouseEnter = () => {
    if (window.innerWidth < 960) {
      setResearchAndDevlopDropdown(false);
    } else {
      setResearchAndDevlopDropdown(true);
    }
  };
  const onResAndDevMouseLeave = () => {
    if (window.innerWidth < 960) {
      setResearchAndDevlopDropdown(false);
    } else {
      setResearchAndDevlopDropdown(false);
    }
  };
  return (
    <div>
      <nav className="navbar">
        <Link
          to="/"
          className="navbar-logo"
          onClick={(closeMobileMenu, () => SetMenuItem("Home"))}
        >
          <img src={DelNovaLOGO} alt="DelNova logo"></img>
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item" onClick={() => setClick(false)}>
            <Link
              to="/"
              className={
                menuSelected === "Home" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => SetMenuItem("Home"))}
              refresh="true"
            >
              HOME
            </Link>
          </li>
          <li className="nav-item" onClick={() => setClick(false)}>
            <Link
              to="/AboutUs"
              className={
                menuSelected === "AboutUs" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => SetMenuItem("AboutUs"))}
            >
              ABOUT
            </Link>
          </li>
          <li
            className="nav-item"
            onMouseEnter={onResAndDevMouseEnter}
            onMouseLeave={onResAndDevMouseLeave}
            onClick={() => setClick(false)}
          >
            <Link
              to="/ResearchAndDevlop"
              className={
                menuSelected === "ResearchAndDev"
                  ? "Active nav-links"
                  : "nav-links"
              }
              onClick={(closeMobileMenu, () => SetMenuItem("ResearchAndDev"))}
            >
              {/* RESEARCH & DEVELOPMENT */}PRODUCTS&nbsp;
              <i className="fas fa-caret-down"></i>
            </Link>
            {ResearchAndDevlopDropdown && <ResAndDevDropdown />}
          </li>
          {/* <li className="nav-item" onClick={() => setClick(false)}>
            <Link
              to="/OurPresence"
              className={
                menuSelected === "OurPresence"
                  ? "Active nav-links"
                  : "nav-links"
              }
              onClick={(closeMobileMenu, () => SetMenuItem("OurPresence"))}
            >
              PRESENCE
            </Link>
          </li> */}
          <li className="nav-item" onClick={() => setClick(false)}>
            <Link
              to="/Pipeline"
              className={
                menuSelected === "Pipeline" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => SetMenuItem("Pipeline"))}
            >
              SERVICES
            </Link>
          </li>
          <li className="nav-item" onClick={() => setClick(false)}>
            <Link
              to="/Careers"
              className={
                menuSelected === "Careers" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => SetMenuItem("Careers"))}
            >
              CAREERS
            </Link>
          </li>
          <li className="nav-item" onClick={() => setClick(false)}>
            <Link
              to="/ContactUs"
              className={
                menuSelected === "ContactUs" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => SetMenuItem("ContactUs"))}
            >
              CONTACT
            </Link>
          </li>

          <li className="nav-item">
            <div className="DunsTradeMark">
              {/* <script
                language="JavaScript"
                src="https://dunsregistered.dnb.com"
                type="text/javascript"
              ></script> */}
              {/* <a
                href="https://dunsregistered.dnb.com"
                src="https://dunsregistered.dnb.com"
              > */}
              {/* DUNS #861312255 */}
              {/* </a> */}
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Navbar;

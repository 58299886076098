import React from "react";
import "./FooterDelNova.css";
// import DunsTradeMark from "./DunsTradeMark.js";
import Facebook from "../images/FacebookIcon.png";
import Twitter from "../images/TwitterIcon.png";
// import Insta from "../images/InstaIcon.png";
import LinkedIn from "../images/LinkedInIcon.png";

export default function FooterDelNova() {
  return (
    <div>
      <footer className="footer">
        {/* <h2 className="Centered">DelNova Healthcare</h2> */}
        <div className="SocialMediaLinks">
          <a
            href="https://www.facebook.com/Delnova-Healthcare-905091739661311/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="facebook Icon"></img>
          </a>
          <a
            href="https://twitter.com/delnova1"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} alt="twitter Icon"></img>
          </a>
          {/* <a href="www.instagram.com" rel="noreferrer">
            <img src={Insta} alt="Instagram Icon"></img>
          </a> */}
          <a
            href="https://www.linkedin.com/company/delnova/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedIn} alt="LinkedIn Icon"></img>
          </a>
          <iframe
            src="https://profiles.dunsregistered.com/TpTpmsSeal.aspx?Domain=www.delnovahealth.com&Directory="
            id="TpTpmsSealIFrm"
            title="DelNova Healthcare"
            width="50px"
            // width="160px"
            height="35px"
            // height="130px"
            frameBorder="0"
            scrolling="no"
            allowtransparency="true"
          ></iframe>
        </div>
        <div className="Social_Media_Accounts">{/* <img></img> */}</div>
        <p>Copyright © 2019 DelNova Healthcare- All Rights Reserved.</p>

        {/* <div className="DunsTradeMark">
          <a
            href="https://dunsregistered.dnb.com"
            src="https://dunsregistered.dnb.com"
          >
            DUNS # 861312255
          </a>
        </div> */}
        <div className="DunsLogo">
         
        </div>
      </footer>
    </div>
  );
}

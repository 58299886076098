import React, { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import POMelatoninOralSolution from "../../images/PO-MelatoninOralSolution.jpg";
// Sample products array
const products = [
  {
    name: "Melatonin Oral Solution - 2 mg/ml - 3 mg/ml",
    description:<img src={POMelatoninOralSolution} className="TeamMainPersonIMG" alt=""></img>
,
    color: "#E7AEAF" // Blue
  },
  {
    name: "Nimodipine Oral Solution",
    description: "Stay tuned for more updates!",
    color: "#A9C5BA" // Red
  },
  {
    name: "Abirateron Acetate Powder for Oral Solution",
    description: "Stay tuned for more updates!",
    color: "#F7D7C3" // Green
  },
  // Add more products if needed
];

// Custom styles for left alignment and accordion title color coding
const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '10px',
    maxWidth: '1200px', // Adjust the max-width to control layout width
    margin: 'auto auto', // Center the container horizontally
  },
  accordionTitle: {
    fontWeight: 'bold',
  },
});

const ProductOfferings = () => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles(); // Call the useStyles hook once, at the top level of the component

  // Handle accordion expand/collapse
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.container}>
      <div style={{ width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          <h5 className="HHeading">NEW PRODUCT OFFERINGS </h5>
        </Typography>

        {products.map((product, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              style={{ backgroundColor: product.color }} // Apply color directly in style
            >
              {/* Color coded title */}
              <Typography variant="h6" className={classes.accordionTitle}>
                {product.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{product.description}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default ProductOfferings;

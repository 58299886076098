export const GenericsProductItems = [

{
    srno: 1,
    productID: "1004",
    rLDProduct_Dev: "Gaster (Japan) / Pepcid (UK) / Pepdul (EU)",
    productName: "Famotidine Tablets (10 mg, 20 mg, 40 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Approved By NMPA",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 2,
    productID: "1001",
    rLDProduct_Dev: "Nimotop (EU)",
    productName: "Nimodipine Tablets (30 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Under Review at NMPA",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Austrilia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 3,
    productID: "1002",
    rLDProduct_Dev: "Dectanyl (EU)",
    productName: "Dexamethasone Acetate Tablets (0.5 mg , 0.75 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Under Review at NMPA",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Austrilia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 4,
    productID: "1005",
    rLDProduct_Dev: "Plaquenil (USA)",
    productName: "Hydroxychloroquine Sulphate Tablets (200 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Approved By USFDA",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 5,
    productID: "1010",
    rLDProduct_Dev: "Zyvox (EU)",
    productName: "Linezolid Tablets (600 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Under Review by TGA",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: false,
      },
      {
        key: "S.E. Asia",
        value: false,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 6,
    productID: "1007",
    rLDProduct_Dev: "Benicar HCT",
    productName: "Olmesartan + HCTZ Tablets (20 + 12.5, 40 + 12.5, 40 + 25 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Ready for Filing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: false,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 7,
    productID: "1009",
    rLDProduct_Dev: "Prochlorparazine Tablets (USA)",
    productName: "Prochlorprrazine Maleate Tablets (5 mg and 10 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Dossier Filed",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 8,
    productID: "1011",
    rLDProduct_Dev: "USL (USA)",
    productName: "Chlorpromazine Hydrochloride Tablets (10 mg, 25 mg, 50 mg, 100 mg, 200 mg )",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Dossier Filed",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 9,
    productID: "1015",
    rLDProduct_Dev: "Imdur",
    productName: "Isosorbite Mononitrate Sustained Release Tablets (60 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Under Filing",
    // avaliableIn: ["USA", "EU", "Australia", "S.E. Asia", "Other"],
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Austrilia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 10,
    productID: "1003",
    rLDProduct_Dev: "Lipitor",
    productName: "Atorvastatin Calcium Trihydarte Tablets (10, 20, 40, 80 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "",
    remark: "Under Filing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 11,
    productID: "1006",
    rLDProduct_Dev: "Lyrica (EU)",
    productName: "Pregabalin Capsules (25, 50, 75, 100, 150, 200, 225, & 300 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Dossier Filed",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: false,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 12,
    productID: "1007",
    rLDProduct_Dev: "Xeljanz (USA)",
    productName: "Tofacitinib Oral solution (1mg/ml)",
    productDevStatus: "Done",
    pilotBE: "NA",
    scaleUp: "Done",
    pivotalBE: "NA",
    technologyTransfer: "",
    remark: "Dossier Under Filing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 13,
    productID: "1021",
    rLDProduct_Dev: "USA",
    productName: "Dexamethasone Tablets 1,2,4,6 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Ready for TT",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 14,
    productID: "1022",
    rLDProduct_Dev: "Coarsucam (UK)",
    productName: "Artesunate and Amodiaquine tablets (25/67.5, 50/135, 100/270 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Ready for TT for WHO Submission",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: false,
      },
    ],
  },

  {
    srno: 15,
    productID: "1022",
    rLDProduct_Dev: "Malafan (UK))",
    productName: "Sulfadoxine and Pyrimethamine tablets (500/25 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Ready for TT for WHO Submission",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: false,
      },
    ],
  },
  {
    srno: 16,
    productID: "1008",
    rLDProduct_Dev: "Dulcolax DR Tablets",
    productName: "Bisacodyl Enteric Coated Tablets (5 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Ready for Tech Transfer",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 17,
    productID: "1014",
    rLDProduct_Dev: "Folic Acid Tablets (EU)",
    productName: "Folic Acid Tablets 5 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Product Under Registration",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 18,
    productID: "1014",
    rLDProduct_Dev: "Folic Acid Tablets (EU)",
    productName: "Folic Acid Tablets 0.4 and 1.0 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Ready for Tech Transfer",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 19,
    productID: "1014",
    rLDProduct_Dev: "Lasix (EU)",
    productName: "Furosemide Tablets (10,20,40 mg)",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Ready for Tech Transfer",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 20,
    productID: "1018",
    rLDProduct_Dev: "Riamet",
    productName: "Artemether + Lumefantine (20+120 mg and 80+480 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready for Tech Transfer. Pilot BE Successful.",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 21,
    productID: "2026",
    rLDProduct_Dev: "Circardin",
    productName: "Melatonin Prolonong Released Tablets (2 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE:"Done",
    technologyTransfer: "Done",
    remark: "Filed with MHRA. Approval Awaited.",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 22,
    productID: "1019",
    rLDProduct_Dev: "Zytiga",
    productName: "Abiraterone Acetate Tablets 250 and 500 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Ready for Tech Transfer",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 23,
    productID: "1017",
    rLDProduct_Dev: "Glumetza",
    productName: "Metformin ER Tablets (500 and 1000 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Dossier Filed",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: false,
      },
    ],
  },
  {
    srno: 24,
    productID: "1012",
    rLDProduct_Dev: "Noxafil (USA)",
    productName: "Posaconazole Delayed Release Tablets 100 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "ANDA Approved",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 25,
    productID: "1013",
    rLDProduct_Dev: "Bradion (USA /EU)",
    productName: "Sugammadex Injection 100 mg / ml ( 2 ml and 5 ml)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "Dossier Approved",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Australia",
        value: false,
      },
      {
        key: "S.E. Asia",
        value: false,
      },
      {
        key: "Other",
        value: false,
      },
    ],
  },

  {
    srno: 26,
    productID: "2026",
    rLDProduct_Dev: "Colonis (UK)",
    productName: "Melatonin Oral solution (1mg/ml)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE:"Done",
    technologyTransfer: "",
    remark: "EB Batches Planned",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 27,
    productID: "2026",
    rLDProduct_Dev: "Xeljanz (USA)",
    productName: "Tofacitinib Immediate Release Tablets (5 and 10 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE:"",
    technologyTransfer: "",
    remark: "EB Batches Planned",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  
  {
    srno: 28,
    productID: "2026",
    rLDProduct_Dev: "Invega (USA/EU)",
    productName: "Paliperidone Extended Release Tablets (1.5, 3.0, 6.0, 9.0 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE:"",
    technologyTransfer: "",
    remark: "EB Batches Planned",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 29,
    productID: "2026",
    rLDProduct_Dev: "Ketosterile (UK)",
    productName: "Alpha keto analogues of Essential Amino Acids Tablets",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE:"",
    technologyTransfer: "",
    remark: "EB Batches Planned",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },




];
import React from "react";
import ContactUsForm from "./ContactUsForm";
import { useLocation } from "react-router-dom";
import "./ShareYourIdea.css";

const ShareYourIdea = () => {
  const location = useLocation();
  const { from } = location.state ? location.state : "Hello";
  // const { from } = location.state;
  return (
    <div className="ShareYourIdea">
      <ContactUsForm HeadLine={from} />;
    </div>
  );
};

export default ShareYourIdea;

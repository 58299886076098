import React from "react";
import { Link } from "react-router-dom";
import DiffIcon from "../../images/pharmaceutical.png";
import InnoIcon from "../../images/innovation.png";
import CompIcon from "../../images/medicine.png";
import GenrIcon from "../../images/pills (1).png";
// import RiBHUS from "../../images/RiBHUS12.png";

import "../../App.css";
import "./ResearchAndDevlop.css";
const ResearchAndDevlop = () => {
  return (
    <div className="FullSize researchAndDevelop">
      <div className="ResearchAndDevelopPage">
        <div className="ResearchAndDevelopBG">
          <h1 className="HHeading">
          Development Partner for
          <br></br>
          Generics Formulations | Complex Generics Formulations | Differentiated Formulations | Robust Process

          </h1>
        </div>
        <div className="Discription">
          <p>
            Will help to meet the time-lines and cost thereby achieving the
            mission &nbsp;
            <span className="newFontTagline">
              “Reducing the Burden of Healthcare”.
            </span>
          </p>
        </div>
        <div className="Border_Bottom"></div>
        <div className="PagesCards">
          <div className="PageCard4">
            <h1 className="PageCardTitle">Generics Formulation</h1>
            <img className="PageCardTitleIcon" src={GenrIcon} alt="About"></img>
            <p className="PageDiscriptionShort">
            <b>We know value of time in Generic Product Development. </b>
            <br></br>
            At DelNova we have a team of experienced product development scientists, who work hand in hand, 
            with analytical and other cross functional teams to work on expedite timelines for Generic product development. We help our partners to meet timelines with cost effective formulations and Process.

            </p>
            <p className="PageLink">
              <Link className="hover-underline-animation" to="/GenericsProduct">
                Learn more
              </Link>
            </p>
          </div>
          <div className="PageCard3">
            <h1 className="PageCardTitle">Complex Generics Formulation</h1>
            <img className="PageCardTitleIcon" src={CompIcon} alt="About"></img>
            <p className="PageDiscriptionShort">
            <b>We understand complexity of Product and Process.</b>
            <br></br>
            Our openness in thinking and listening, will help us to develop innovative approaches for Complex Formulations. 
            We convert complex formulations to Bioequivalent Generics with our Quality iN Design approach.  
            <br></br>
            We believe in <b> <i>Do Right at First time </i></b> and Our supply chain team will help us to get the raw material promptly and thereby delivery of prototype on time. Thus, we help our partner to keep abreast in competition like First to File or First to Market Opportunity. 

            </p>
            <p className="PageLink">
              <Link className="hover-underline-animation" to="/ComplexGenerics">
                Learn more
              </Link>
            </p>
          </div>
          <div className="PageCard1">
            <h1 className="PageCardTitle">Differentiated Formulation</h1>
            <img className="PageCardTitleIcon" src={DiffIcon} alt="About"></img>
            <p className="PageDiscriptionShort">
            Our highly qualified, experienced formulation and analytical team having expertise in developing formulations their methods, with unique and cost-effective process for our partners. Our Infrastructure and capability help us to develop, simple yet unique process. With our technology platform we create our own IPs. 
            Our Solvent free technologies and Design of Experiment studies will create differentiated formulations and process. 
            We explore each drug substance. We touch, with aim to make something new from it, for our patients, which is safe, effective and economical. 

            </p>
            <p className="PageLink">
              <Link
                className="hover-underline-animation"
                to="/DifferntiatedProdDev"
              >
                Learn more
              </Link>
            </p>
          </div>

          <div className="PageCard2">
            <h1 className="PageCardTitle">Innovative Formulation</h1>
            <img className="PageCardTitleIcon" src={InnoIcon} alt="About"></img>
            <p className="PageDiscriptionShort">
            <b>We believe - An Idea can change life.
            Our Platform Technologies are with aim of Reducing Burden of Healthcare. </b>
            <br></br>
            Our collaboration with Institutes, renowned academicians and budding scientist across the globe is helping us to get the best of thoughts. We support NCE companies to develop the First -In Human Formulations and while we encourage our scientist to generate IPs with Differentiated and Innovative Formulations. 
            We encourage the thoughts and give the platform to such scientists and startup companies, so the Patients can be benefited with innovative product miracles.

            </p>
            <p className="PageLink redirectToRiBHUS">
              <a 
                href="https://ribhusinnovation.com/innovativeproducts"
                target="_blank"
                rel="noreferrer"
              >
               <span 
                className="hover-underline-animation"
                > Learn more</span>
                {/* <img src={RiBHUS} alt="RiBHUS redirect"></img> */}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResearchAndDevlop;

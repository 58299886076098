import React from "react";

const DunsTradeMark = () => {
  return (
    <div>
      {/* <h1> duns Logo </h1> */}

      <script
        language="JavaScript"
        src="https://dunsregistered.dnb.com"
        type="text/javascript"
      ></script>
    </div>
  );
};

export default DunsTradeMark;

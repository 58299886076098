import React from "react";
import "../../App.css";
import "./CompanyOverview.css";

const CompanyOverview = () => {
  return (
    <div className="FullSize companyOverview">
      <div className="companyOverviewPage">
        {/* <h1 className="HHeading">Company Overview</h1> */}
        <div className="WhoWeAre">
          <h4>WE ARE DIFFERENT</h4>
          <p>
          We are research driven, innovative pharmaceutical company focused on &nbsp;
            <span className="newFontTagline">
              “Reducing the burden of Healthcare”,
            </span>, through alliance in development and manufacturing. 
          We aim to reduce healthcare cost by providing simple solution to ever challenging problems faced by generic and innovator companies. 
          We help our partners to develop and establish robust and reproducible process producing stable and bioequivalent compositions.
          
            {/* &nbsp;through alliance in development and manufacturing. We aim to reduce healthcare cost by providing simple solution to ever challenging problems faced by generic and innovator companies. We help our partners to develop and establish robust and reproducible process prodcuing stable and bioequivalent compositions.  */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;

import React from "react";
import "../../App.css";

const OutLicensing = () => {
  return (
    <div className="FullSize">
      <h1 className="outLicensing">Out Licensing</h1>
    </div>
  );
};

export default OutLicensing;

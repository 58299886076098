export const ResAndDevMenuItems = [
  // {
  //   title: "Available For Licensing",
  //   path: "/AvailableForLicensing",
  //   cName: "dropdown-link",
  // },
  {
    title: "Generics Product",
    path: "/GenericsProduct",
    cName: "dropdown-link",
  },
  {
    title: "Complex Generics Product",
    path: "/ComplexGenerics",
    cName: "dropdown-link",
  },
  {
    title: "Differentiated Product ",
    path: "/DifferntiatedProdDev",
    cName: "dropdown-link",
  },
  {
    title: "Innovative Product ",
    path: "/InnovativeProdDev",
    cName: "dropdown-link",
  },
];

import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { InnovativeProdDevItems } from "./InnovativeProdDevItems";

import "../../App.css";
import ContactUsCard from "../ContactUsCard";
import RiBHUS from "../../images/RiBHUS12.png" 

const InnovativeProdDev = () => {
  const [divName, setDivName] = useState("");
  const OpenLicensing = (tableElement) => {
    if (divName === tableElement) {
      setDivName("");
    } else {
      setDivName(tableElement);
    }
  };
  const renderBody = () => {
    return InnovativeProdDevItems.map(
      (
        {
          srno,
          productID,
          rLDProduct_Dev,
          productName,
          productDevStatus,
          ipStatus,
          labStability,
          poc,
          licensing,
          remark,
          avaliableIn,
        },
        id
      ) => {
        return (
          <div className="ProductSection" key={id}>
            {/* <h1>{srno}</h1> */}
            <h2 className="ProductName">{productName}</h2>
            <h4 className="ProductRLDdev">{rLDProduct_Dev}</h4>
            <button
              className="onclickoutlic"
              onClick={() => {
                OpenLicensing(productName);
              }}
            >
              Reach For Licensing
            </button>
            <section className="Tbody">
              <div className="table-row">
                {/* <td>{productID}</td> */}
                {/* // */}
                {productDevStatus === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>IP Filed</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress BlueProLine">
                    <i className="fas fa-sync"></i>
                    <p>IP Files</p>
                  </div>
                )}
                {ipStatus === "" ? (
                  <div className="ProdDevProgress OrangeProLine">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>POC</p>
                  </div>
                ) : ipStatus === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>POC</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress GrayProLine">
                    <i className="fas fa-times-circle"></i>
                    <p>POC</p>
                  </div>
                )}
                {labStability === "" ? (
                  <div className="ProdDevProgress OrangeProLine">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>Scale Up</p>
                  </div>
                ) : labStability === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>Scale Up</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress GrayProLine">
                    <i className="fas fa-times-circle"></i>
                    <p>Scale Up</p>
                  </div>
                )}
                {poc === "" ? (
                  <div className="ProdDevProgress OrangeProLine">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>In - Vivo Study</p>
                  </div>
                ) : poc === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>In - Vivo Study</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress GrayProLine">
                    <i className="fas fa-times-circle"></i>
                    <p>In - Vivo Study</p>
                  </div>
                )}

                {licensing === "" ? (
                  <div className="ProdDevProgress OrangeProLine">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>Tech. Transfrer</p>
                  </div>
                ) : licensing === "Done" ? (
                  <div className="ProdDevProgress GreenProLine">
                    <i className="fas fa-check-circle"></i>
                    <p>Tech. Transfrer</p>
                  </div>
                ) : (
                  <div className="ProdDevProgress GrayProLine">
                    <i className="fas fa-times-circle"></i>
                    <p>Tech. Transfrer</p>
                  </div>
                )}
              </div>
            </section>
            <div
              className={
                productName === divName
                  ? "OutLicensing_List viewMore"
                  : "OutLicensing_List"
              }
            >
              <table className="OutLicTable">
                <thead className="OutLicTableHead">
                  <tr>
                    <th className="OutLicTableHeadValue">Region</th>
                    <th className="OutLicTableHeadValue">USA</th>
                    <th className="OutLicTableHeadValue">EU</th>
                    <th className="OutLicTableHeadValue">China</th>
                    <th className="OutLicTableHeadValue">Australia</th>
                    <th className="OutLicTableHeadValue">S.E. Asia</th>
                    <th className="OutLicTableHeadValue">Other</th>
                  </tr>
                </thead>
                <tbody className="OutLicTableBody">
                  <tr className="OutLicTableBodyRow">
                    <td className="OutLicTableBodyValue">Status</td>
                    {avaliableIn.map(function (item, id) {
                      return (
                        <td
                          className={
                            item.value
                              ? "outLicTableValue Avaliable"
                              : "outLicTableValue notAvaliable"
                          }
                          key={id}
                        >
                          {item.value ? (
                            <i className="fas fa-check tooltip">
                              <span className="tooltiptext">Avaliable</span>
                            </i>
                          ) : (
                            <i className="fas fa-times tooltip">
                              <span className="tooltiptext">Not Avaliable</span>
                            </i>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="outLicRemarkRow">
                    <td className="outLicRemarValue"> Remarks</td>
                    <td className="outLicRemarValue" colSpan={6}>
                      {remark}
                    </td>
                  </tr>
                  <tr className="outLicContactUsBTNRow">
                    <td className="outLicContactUsBTN" colSpan={7}>
                      <Link className="ProductTableBTN" to="/ContactUs">
                        Contact Us
                      </Link>
                    </td>
                  </tr>
                  {/* <tr className="outLicRemarkRow">
                    <td className="outLicRemarValue" colSpan={7}>
                      <Link to="/ContactUs">contact Us</Link>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    );
  };
  // return (
  //   <div className="FullSize InnovativeProduct">
  //     <div className="PipelinePage">
  //       <div className="InnovativeProductTopBG"></div>
  //       <div className="Pipeline">
  //         <h1 className="HHeading">Innovative Product</h1>
  //       </div>
  //       <div className="product_List">
  //         <p className="ShortDiscription">
  //           We are built on patient-focused innovation and development of drug
  //           delivery platforms, biosimilars, and prodrugs with applications in a
  //           wide variety of molecules to improve their clinical utility.
  //         </p>
  //         <h1 className="HHeading">Innovative Products: </h1>
          // <div className="ProdDevProgressDetails">{renderBody()}</div>
  //       </div>
  //     </div>
  //     <ContactUsCard sender={"Inno"} />
  //   </div>
  // );
  return (
    <div className="home otherProduct">
      <div className="LinkToDelNova">
        <h1 className="RedirectMSG">
          For More Product details please visit our Affiliated partner
        </h1>
        <a
          href="https://ribhusinnovation.com/Proddev"
          target="_blank"
          rel="noreferrer"
        >
          <img src={RiBHUS} alt="delnova redirect"></img>
        </a>
      </div>
      <div className="Border_Bottom"></div>
      <div className="FullSize GenericsProduct">
      <div className="PipelinePage">
        {/* <div className="GenericsProductTopBG"></div> */}
        <div className="Pipeline">
          {/* <h1 className="HHeading">Generics Product </h1> */}
        </div>

        <div className="product_List">
          {/* <p className="ShortDiscription">
          We identify and understand complexity and challenges in branded formulations. Our development will help to have clinically equivalent products with robust and cost effective process. Thus provide affordable medicines to improve the overall quality of life for all patients.
          </p> */}
          <h1 className="HHeading">Innovative Products:</h1>
          <div className="ProdDevProgressDetails">{renderBody()}</div>
        </div>
      </div>
      <ContactUsCard sender={"Genr"} />
    </div>
      {/* <div className="ProdDevProgressDetails">{renderBody()}</div> */}
      <div className="Border_Bottom"></div>

      <div className="Discription">
      <p>
          Product Development Partner for the ‘Novel Drug Delivery Systems’
          <br></br> Our Differentiated | Innovative Technology and Processes,<br></br>
          will help our Partners to extend Product Lifecycle and comeup with cost effective development. Our Platform Technologies will Patented, Simple and Commercially Viabale with Patient Centric approch.
        </p>
      </div>
      <div className="Border_Bottom"></div>
      
    </div>

  );
};

export default InnovativeProdDev;
